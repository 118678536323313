:root {

  --base-font-family: 'Open Sans', sans-serif;

  // COLORS

  --white: #ffffff;
  --black: #000000;
  --blue-dark: #006385;
  --blue-light: #53a0ba;
  --deep-sea-blue: #005f87;
  --SIE-Blue-17-Light: #50bed7;
  --yellow-dark: #eb780a;
  --yellow-light: #ffb900;
  --SIE-Blue-1: #003750;
  --SIE-Blue-3: #004669;
  --hover-yellow: #ffcd50;
  --PL_Black_4: #1e1e1e;
  --PL_Black_8: #464646;
  --PL_Black_1-30: rgba(0, 0, 0, 0.3);
  --grey-border: #eeeeee;
  --orange: #dc6914;
  --selection-hover-grey: #daecf0;
  --SIE-Natural-Blue-9: #afd7e1;
  --SIE-Natural-Blue-10: #cde6eb;

  // -------------------------------------------------------------------------------------------------------------------

  --frame-text-color: var(--white);

  --base-text-color: var(--black);
  --base-icon-color: white;
  --controller-base-border-color: #828282;

  --table-row-hover-color: #eef6f8;

  // HEADER
  --header-bg-color: #016588;
  --header-button-pressed: #003750;
  --header-button-hovered: rgba(0,55,80,0.6);
  --header-submenu-text-hovered: rgb(255,205,80);
  --header-submenu-border-color: #789199;
  --header-submenu-bg-color: var(--SIE-Blue-1);
  --header-text-color: #FFFFFF;

  // SIDEBAR


  // SEARCH

  //DIALOG TYPES
  --dialog-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --dialog-border: solid 1px #dcdcdc;

  // FOOTER
  --footer-bg-color: rgba(0, 60, 85, 0.5);

  // PROGRESS BAR
  --pb-buffer-bg: #003C55;
  --pb-active-color: #FFB900;

  // FORM COMPONENTS
  --controller-base-bg-color: white;

  // SWITCH BAR (MAPS/FACILITY)
  --switch-bar-icon-hover-color: var(--hover-yellow);
  --switch-bar-bg-color: rgba(0,60,85, 1);
  --switch-bar-border-color: rgba(0,60,85, 0.6);
  --switch-bar-selected-color: #003C55;

  // SITES MENU
  --selected-site-bg-color: #00547C;

  // TOOLTIP
  --tooltip-err-bg-color: #fbeeed;
  --tooltip-err-border-color: #E38984;
  --tooltip-err-text-color: #dc0000;

  --tooltip-info-bg-color: #ffffff;
  --tooltip-info-border-color: #dcdcdc;
  --tooltip-info-text-color: #464646;

  --tooltip-box-shadow-color: rgba(0, 0, 0, 0.3);

  // SPECIAL ICONS
  --error-icon-color: #dc0000;
  --tooltip-require-color: #005f87;

  // Forms
  --form-text-color: #1e1e1e;
  --label-invliad-color: var(--error-icon-color);
  --line-color: #AAAAAA;

  // INPUT-BOX
  --input-shadow-color: var(--tooltip-box-shadow-color);
  --input-border-color: var(--controller-base-border-color);
  --input-invalid-border-color: var(--label-invliad-color);
  --input-invalid-background-color: #fbe5e5;
  --input-empty-bg-color: #ffffff;
  --input-filled-bg-color: #e3faff;
  --input-placeholder-color: #aaaaaa;
  --input-text-color: var(--form-text-color);
  --input-disabled-bg-color: #f0f0f0;
  --input-selected-border-color: #3296b9;

  // CHECKBOX
  --checkbox-bg-color: #ffffff;
  --checkbox-border-color: #464646;
  --checkbox-border-hovered-color: #197fa2;
  --checkbox-pressed-bg-color: var(--checkbox-border-hovered-color);
  --checkbox-disabled-color: #aaaaaa;
  --checkbox-ripple-bg-color: #b0f1ff80; // with transparent value set to 0.5

  // RADIO GROUP
  --radio-bg-color: #ffffff;
  --radio-border-color: #464646;
  --radio-border-hovered-color: #197fa2;
  --radio-pressed-bg-color: var(--radio-border-hovered-color);
  --radio-disabled-color: #aaaaaa;
  --radio-ripple-bg-color: #b0f1ff80; // with transparent value set to 0.5

  // SELECTION BOX
  --select-box-shadow-color: var(--tooltip-box-shadow-color);
  --select-box-header-border-color: var(--controller-base-border-color);
  --select-box-header-selected-border-color: #3296b9;
  --select-box-list-border-color: #dcdcdc;
  --select-box-row-hovered-bg-color: var(--selection-hover-grey);
  --select-box-row-selected-bg-color: #cde6eb;
  --select-box-row-disabled-color: #e5e5e5;
  --select-box-row-disabled-border-color: #aaaaaa;
  --select-box-row-disabled-text-color: #aaaaaa;

  // DATE PICKER
  --datepicker-selected-date-bg-color: #005f87;
  --datepicker-icon-fill-color: #464646;
  --datepicker-current-date-bg-color: #dc6914;
  --datepicker-disabled-bg-color: #f0f0f0;
  --datepicker-icon-disabled-color: #aaaaaa;

  // DIALOG
  --dialog-header-title-color: #FFFFFF;
  --dialog-header-bg-color: #005f87;
  --dialog-header-close-icon-color: #FFFFFF;

  // BUTTON
  --button-text-color: #FFFFFF;

  --button-blue-bg-color: #005f87;
  --button-blue-hovered-bg-color: #004669;
  --button-blue-pressed-bg-color: #004669;
  --button-blue-bg-border-color: #004669;

  --button-disabled-text-color: #aaaaaa;
  --button-disabled-bg-color: #ffffff;
  --button-disabled-bg-border-color: #aaaaaa;

  --button-orange-bg-color: #dc6914;
  --button-orange-hovered-bg-color: #b44b28;
  --button-orange-pressed-bg-color: #b44b28;
  --button-orange-bg-border-color: #b44b28;

  --button-red-bg-color: #dc0000;
  --button-red-hovered-bg-color: #b00000;
  --button-red-pressed-bg-color: #b00000;
  --button-red-bg-border-color: #b00000;

  --button-white-bg-color: #ffffff;
  --button-white-hovered-bg-color: #F0F7F9;
  --button-white-pressed-bg-color: #F0F7F9;
  --button-white-bg-border-color: #05668B;
  --button-white-text-color: #05668B;

  // NOTIFICATION
  --notification-blue-icon-bg-color: #f0f0f0;
  --notification-red-icon-bg-color: #f7dddc;
  --notification-orange-icon-bg-color: #fff09b;

  // SLIDER
  --slider-color: #2387aa;
  --slider-base-color: #46464633;
  --slider-font-base-color: #aaaaaa;

  --hyperlink-or-clickable-text: #55a0b9;
  --hyperlink-or-clickable-text-hover: #2882a0;
}
