.ps__thumb-y {
  right: 0 !important;
  background-color: #335F73 !important;
  border-radius: 4px !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 6px !important;
}

.ps-content {
  margin-right: 16px !important;
}

.ps__rail-y {
  width: 6px !important;
  margin-right: 4px !important;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: none;
  background-color: transparent;
}

.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y,
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 0.6 !important;
  background-color: transparent !important;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  opacity: 1 !important;
  background-color: transparent !important;
}
