.mat-checkbox-inner-container {
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--checkbox-pressed-bg-color) !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: var(--checkbox-disabled-color) !important;
}

.mat-checkbox-frame {
  background-color: var(--checkbox-bg-color) !important;
  border-color: var(--checkbox-border-color) !important;
  border-width: 1px !important;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: var(--checkbox-disabled-color) !important;
}

.mat-tree-node {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px;
  color: var(--base-text-color);
  min-height: auto !important;

  &:hover {
    background-color: var(--selection-hover-grey);
  }
}

.mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-layout:hover .mat-checkbox-inner-container .mat-checkbox-frame{
  border-width: 2px !important;
  border-color: var(--checkbox-border-hovered-color) !important;
}

.mat-checkbox {
  font-family: var(--base-font-family) !important;
  font-size: 13px;
}

.mat-ripple-element {
  background: var(--checkbox-ripple-bg-color)!important;
}

.mat-checkbox-ripple {
  left: calc(50% - 22px) !important;
  top: calc(50% - 22px) !important;
  height: 44px !important;
  width: 44px !important;
}

.mat-tree {
  background-color: transparent;
}

.mat-progress-bar-buffer {
  background-color: var(--pb-buffer-bg);
}
.mat-progress-bar-fill::after {
  background-color: transparent;
}

.mat-progress-bar .mat-progress-bar-fill {
  background-color: var(--pb-active-color) !important;
  border-radius: 5px !important;
}

.mat-expansion-indicator::after {
  color: var(--base-icon-color);
}

.mat-expanded {
  //background-color: var(--selected-site-bg-color) !important;
}

// Radio
.mat-radio-ripple {
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  height: 44px;
  width: 44px;
}

.mat-radio-outer-circle {
  height: 18px !important;
  width: 18px !important;
  border-width: 1px !important;
  border-color: var(--radio-border-color);
}

.mat-radio-inner-circle {
  height: 18px !important;
  width: 18px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6) !important;
}

.mat-radio-button:not(.mat-radio-disabled).mat-accent .mat-radio-inner-circle {
  background-color: var(--radio-pressed-bg-color);
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--radio-disabled-color);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--radio-pressed-bg-color);
}

.mat-radio-button:not(.mat-radio-disabled) .mat-radio-label:hover .mat-radio-container .mat-radio-outer-circle{
  border-width: 4px !important;
  border-color: var(--radio-border-hovered-color) !important;
}

.mat-radio-button {
  line-height: 24px;
  font-family: var(--base-font-family) !important;
  font-size: 13px;
}

.mat-radio-label-content {
  padding-left: 4px !important;
}

.mat-radio-button:not(.mat-radio-disabled).mat-radio-checked .mat-radio-label:hover .mat-radio-container .mat-radio-outer-circle{
  border-width: 2px !important;
  border-color: var(--radio-border-hovered-color) !important;
}

// Date picker
.mat-calendar-body-selected {
  background-color: var(--datepicker-selected-date-bg-color);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: var(--datepicker-current-date-bg-color);
}

td[colspan]:not([colspan="7"]){
  visibility: hidden;
}

td[colspan][colspan="7"]{
  display: none;
}

// Expansion box
.mat-expansion-panel {
  box-shadow: none;
  background-color: transparent;
  color: var(--base-text-color);
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel-header {
  padding: 0 5px !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;

  &.mat-expanded {
    font-weight: bold !important;
  }
}

.mat-expansion-panel-body {
  padding: 0 10px 5px !important;
}

.mat-content {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical !important;
}

.mat-checkbox-layout {
  max-width: 100%;
}

.mat-checkbox-layout .mat-checkbox-label {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px; // to ignore cut off italic text
}

.mat-calendar {
  height: auto !important;
}

.cdk-overlay-container {
  z-index: 4000;
}

// ---------------------------------------------

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ffcd50 !important;
}

// hover color for the date picker hover
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: var(--selection-hover-grey) !important;
}

.mat-calendar-period-button:hover {
  background-color: var(--selection-hover-grey) !important;
}
