/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './Themes/Appollo.scss';
// @import './Themes/IntositeColors.scss';

// Style override using polyfills
@import './ScssPolyfills/material-polyfill.scss';
@import './ScssPolyfills/perfect-scrollbar-polyfill.scss';

@import './assets/tour/css/tour.css';
@import './assets/tour/css/font-awesome.min.css';

html, body{
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif !important;
  color: var(--base-text-color);
}

.mat-icon {
  overflow: visible !important;
}

textarea:focus, input:focus {
  outline: none;
}

@media screen {
  .printable { display: none !important;}
  .non-printable { display: block !important;}
}
@media print {
  .non-printable { display: none !important;}
  .printable { display: block !important;}
}
.mat-tab-body-content {
  overflow-y: hidden !important;
}

.mat-tab-header, .mat-tab-nav-bar {
  border-bottom: 0 !important;
  margin-bottom: 12px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

.mat-tab-label {
  font-size: 14px !important;
  color: #1E1E1E !important;
  opacity: 1 !important;
  padding: 0px 20px 0px 20px !important;
  font-weight: 600 !important;
  font-family: Open Sans !important;
  justify-content: flex-start;
  min-width: 150px !important;
}

.mat-tab-label.mat-tab-label-active {
  color: #05668B !important;
  opacity: 1 !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  border-bottom: 2px solid #05668B !important;
}

.mat-tab-label-content {
  margin-bottom: -25px;  
}

.mat-tab-label-container {
  margin-top: -25px !important;
}